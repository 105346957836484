.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left_button,
.right_button {
  padding: 4px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.right_button {
  background-color: #28a745;
}